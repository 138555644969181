import { withApolloClient } from 'lib/apollo/withApolloClient';
import Error404 from 'pages/Error404';

function Error() {
  return (
    <Error404 />
  );
}

export default withApolloClient(Error);
